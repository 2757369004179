import React from 'react';

import hands from "../../assets/images/roadmap/16_roadmap-slider.png";
import hands_mobile from "../../assets/images/roadmap/hands_roadmap_mobile2.png";
import r_17 from "../../assets/images/roadmap/17_q4.jpg";
import r_18 from "../../assets/images/roadmap/18_q1.jpg";
import r_19_u from "../../assets/images/roadmap/19_1_unicef.png";
import r_19_a from "../../assets/images/roadmap/19_2_artwork.jpg";
import r_20 from "../../assets/images/roadmap/20_socks.png";
import r_21 from "../../assets/images/roadmap/21_book.png";
import r_22 from "../../assets/images/roadmap/22_braccia_slider_x1.jpg";
import r_22_x2 from "../../assets/images/roadmap/22_braccia_slider_x2.jpg";
import r_22_mobile from "../../assets/images/roadmap/the_socks_family_franks_2.jpg"
import r_23 from "../../assets/images/roadmap/23_fashion.jpg";
import r_24 from "../../assets/images/roadmap/24_homegoods.jpg";
import r_24_mobile from "../../assets/images/roadmap/the_socks_family_goods.jpg";
import r_25_mobile from "../../assets/images/roadmap/the_socks_family_franks_3.jpg"
import r_25 from "../../assets/images/roadmap/25_slider_x1.jpg";
import r_25_x2 from "../../assets/images/roadmap/25_slider_x2.jpg";

import mj from "../../assets/images/roadmap/mj.gif"
import { Tooltip } from 'flowbite-react';


function Roadmap() {
    return ( 
        <section className=''>
            <div className='bg-[#03FFA6] text-center'>
                <h1 className='text-4xl md:text-4xl lg:text-5xl font-black pt-8'>roadmap</h1>
                <p className='px-10 lg:px-20 md:text-2xl lg:text-3xl pt-5 pb-10'>Our roadmap includes 9 nft projects, garments, goods, graphic nouvel, tv series, videogame and a movie. <br /> No bullshit, we are just ambitious and competent (even if we pretend to be stupid).</p>
                <section className="hidden lg:block splide" aria-labelledby="carousel-heading">
                    <div className="splide__track">
                            <ul className="splide__list">
                                <li className="splide__slide"><img src={hands} alt="1" /></li>
                                <li className="splide__slide"><img src={hands} alt="1" /></li>
                            </ul>
                    </div>
                </section>
                <img src={hands_mobile} alt="" className='lg:hidden'/>
            </div>
            <div className='bg-[#8AF3FE] text-center'>
                <h1 className='text-3xl px-8 lg:px-0 md:text-4xl lg:text-5xl font-black pt-8 lg:pt-12'>Q4 / 2022 <br />frank the college dropout / nft</h1>
                <p className='px-10 lg:px-40 md:text-xl lg:text-2xl pt-5'>
                This is a capsule collection of 1.023 (wonder what 
                    <span className='inline-block cursor-pointer mx-2'>
                        <Tooltip
                            content={
                                <img src={mj} alt="" className='h-32 z-40'/>
                            }
                            placement='bottom'
                            className=""
                            >
                            <span className='font-black underline underline-offset-4 decoration-gold text-gold'>23</span>
                        </Tooltip>
                    </span>
                
               means) nft unique piece of art.
                <br />
                This mint introduces our special daddy to the world.
                <br />
                <br className='hidden lg:block'/>
                What did Frank do before being a family man?
                <br />
                <br className='hidden lg:block'/>
                Some say he dropped out of college to pursue a career in music. <br />It would seem that Frank launched a guy named Ye, a guy who made a decent music career…
                </p>
                <img src={r_17} alt="" className='md:w-4/5 lg:w-2/5 md:mx-auto pt-6 md:pb-8 lg:py-14 lg:drop-shadow-md'/>
            </div>
            <div className='bg-[#CAC8AB] text-center'>
                <h1 className='text-3xl px-8 lg:px-0 md:text-4xl lg:text-5xl font-black pt-8 lg:pt-12'>Q1 / 2023 <br />frank the sock / nft</h1>
                <p className='px-10 lg:px-40 md:text-xl lg:text-2xl pt-5'>This is the main collection of <b>5.023</b> nft unique piece of art.<br /> (this number is a persecution) <br /><br className='hidden lg:block'/><b>Each Frank's owner will have for free 1 nft. Gina the Sock will be a free mint.</b> <br /> Get a look to the roadmap to discover more.</p>
                <img src={r_18} alt="" className='md:w-4/5 md:pb-8 lg:w-2/5 md:mx-auto pt-6 lg:pt-14 lg:drop-shadow-md'/>
            </div>
            <div className='bg-[#CAC8AB] text-center'>
                <h1 className='text-3xl px-8 lg:px-0 md:text-4xl lg:text-5xl font-black pt-8 lg:pt-12'>Q2 / 2023 <br />mary the sock / nft</h1>
                <p className='px-10 lg:px-40 md:text-xl lg:text-2xl pt-5'>This is a collection of <b>3.500</b> nft unique piece of art.<br /> This mint introduces to the world our special mummy. <br /><br /> 10% of Mary the Sock sales will be destinated to a carefully selected corporation to take care about families and childrens.</p>
                <img src={r_19_u} alt="" className='w-1/2 lg:w-64 mx-auto py-5 drop-shadow-md'/>
                <img src={r_19_a} alt="" className='w-1/2 lg:w-72 mx-auto drop-shadow-md'/>
            </div>
            <div className='bg-[#CAC8AB] text-center'>
                <h1 className='text-3xl px-8 lg:px-0 md:text-4xl lg:text-5xl font-black pt-8 lg:pt-12'>Q4 / 2023 <br />socks collection</h1>
                <p className='px-10 lg:px-40 md:text-xl lg:text-2xl pt-5'>A wearable socks collection will be released on <b>thesocksfamily.net</b>.<br />The collection includes low and medium socks models.<br />In different colorways and graphics.</p>
                <img src={r_20} alt="" className='w-2/3 lg:w-96 mx-auto py-6 pr-5 lg:py-14 drop-shadow-md'/>
            </div>
            <div className='bg-[#FFA5E7] text-center'>
                <h1 className='text-3xl px-8 lg:px-0 md:text-4xl lg:text-5xl font-black pt-8 lg:pt-12'>Q1 / 2024 <br />benny the sock / nft</h1>
                <p className='px-10 lg:px-40 md:text-xl lg:text-2xl pt-5'>This is a collection of <b>2.500 nft</b> unique piece of art. <br className='hidden lg:block'/>This mint introduces to the world our funny little boy.</p>
            </div>
            <div className='bg-[#FFA5E7] text-center'>
                <h1 className='text-3xl px-8 lg:px-0 md:text-4xl lg:text-5xl font-black pt-8 lg:pt-12'>gina the sock / nft</h1>
                <p className='px-10 lg:px-40 md:text-xl lg:text-2xl pt-5'>Gina the Sock will be a <b>free mint</b>. <br /> This is a collection of <b>5.023 nft</b> unique piece of art.<br />This mint introduces to the world our sweet girl. <br className='hidden lg:block'/><b>Each Frank's owner will have for free 1 of these nft.</b></p>
            </div>
            <div className='bg-[#FFA5E7] text-center'>
                <h1 className='text-3xl px-8 lg:px-0 md:text-4xl lg:text-5xl font-black pt-8 lg:pt-12'>Q2 / 2024 <br />comic book</h1>
                <p className='px-10 lg:px-40 md:text-xl lg:text-2xl pt-5'>The n°0 of The Socks Family comic book/graphic novel is the first step of a global project. <br /> It will be made by the hands of Tommaso Bennato, a founder, and cartoonist for Marvel, Delcourt and many more.</p>
                <img src={r_21} alt="" className='w-1/2 lg:w-80 mx-auto pt-8 lg:pt-14 drop-shadow-md'/>
            </div>
            <div className='bg-[#FFA5E7] text-center'>
                <h1 className='text-3xl px-8 lg:px-0 md:text-4xl lg:text-5xl font-black pt-8 lg:pt-12'>Q4 / 2024 <br />the socks family / nft</h1>
                <p className='px-10 lg:px-40 md:text-xl lg:text-2xl pt-5'>The Socks Family is here! <br /> This is a collection of <b>5.045 nft</b> unique piece of art. <br /><br /><b>1st important detail</b><br />Owners of family members will be able to access the Family Main Collection and create their own family picture. Nothing prohibits you from having two Frank or two Mary in your family picture. Love is love. <br /><br /><b>2nd important detail</b><br />With the 100% of the sales of The Socks Family collection we will "thank" all the nft's holders with <b>free</b> fashion garments as socks, t-shirts and hoodies.</p>
                <img src={r_22_mobile} alt="" className='lg:hidden pt-8' />
                <section className="hidden lg:block splide pt-14" aria-labelledby="carousel-heading">
                    <div className="splide__track">
                            <ul className="splide__list">
                                <li className="splide__slide"><img src={r_22} alt="1" /></li>
                                <li className="splide__slide"><img src={r_22} alt="1" /></li>
                            </ul>
                    </div>
                </section>
            </div>
            <div className='bg-white text-center'>
                <h1 className='text-3xl px-8 lg:px-0 md:text-4xl lg:text-5xl font-black pt-8 lg:pt-12'>Q1 / 2025 <br />free celebrative merch</h1>
                <p className='px-10 lg:px-40 md:text-xl lg:text-2xl pt-5'>Get your free "Celebrative Capsule" collection. <br />We'll ship randomly socks, t-shirts and hoodies to all the nft's owners. They have to pay just the postal fee.</p>
                <img src={r_23} alt="" className='w-4/5 lg:w-1/2 mx-auto pt-6 lg:py-14'/>
            </div>
            <div className='bg-white text-center'>
                <h1 className='text-3xl px-8 lg:px-0 md:text-4xl lg:text-5xl font-black pt-8 lg:pt-12'>Q2 / 2025 <br />fashion collection</h1>
                <p className='px-10 lg:px-40 md:text-xl lg:text-2xl pt-5 pb-14'>The first capsule collection will be dropped on <b>thesocksfamily.net</b>.<br />The collection includes t-shirts, hoodies and socks. In different colorways and graphics.</p>
            </div>
            <div className='bg-[#1DFF8B] text-center'>
                <h1 className='text-3xl px-8 lg:px-0 md:text-4xl lg:text-5xl font-black pt-8 lg:pt-12'>Q3 / 2025 <br />mother's day / nft</h1>
                <p className='px-10 lg:px-40 md:text-xl lg:text-2xl pt-5'>This is a collection of <b>1.500 nft</b> unique piece of art.<br />This celebrative collection will be minted on 14th of May.</p>
            </div>
            <div className='bg-[#1DFF8B] text-center'>
                <h1 className='text-3xl px-8 lg:px-0 md:text-4xl lg:text-5xl font-black pt-8 lg:pt-12'>Q3 / 2025 <br />father's day / nft</h1>
                <p className='px-10 lg:px-40 md:text-xl lg:text-2xl pt-5 pb-8 lg:pb-14'>This is a collection of <b>1.500 nft</b> unique piece of art.<br />This celebrative collection will be minted on 18th of June.</p>
            </div>
            <div className='bg-white text-center'>
                <h1 className='text-3xl px-8 lg:px-0 md:text-4xl lg:text-5xl font-black pt-8 lg:pt-12'>Q4 / 2025 <br />homegoods collection</h1>
                <p className='px-10 lg:px-40 md:text-xl lg:text-2xl pt-5'>The first capsule collection will be dropped on <b>thesocksfamily.net</b> <br /> The collection includes t-shirts, hoodies and socks. In different colorways and graphics.</p>
                <img src={r_24} alt="" className='hidden lg:block w-11/12 mx-auto py-14'/>
                <img src={r_24_mobile} alt="" className='lg:hidden pb-8'/>
            </div>
            <div className='bg-[#FEAB44] text-center'>
                <h1 className='text-3xl px-8 lg:px-0 md:text-4xl lg:text-5xl font-black pt-8 lg:pt-12'>Q1 / 2026 <br />web streaming cartoon series</h1>
                <p className='px-10 lg:px-40 md:text-xl lg:text-2xl pt-5'>This project represents the new life of our project. <br /> We're ready to be part of the world of entrtainment.</p>
            </div>
            <div className='bg-[#FEAB44] text-center'>
                <h1 className='text-3xl px-8 lg:px-0 md:text-4xl lg:text-5xl font-black pt-8 lg:pt-12'>Q4 / 2026 <br />animated nft collection</h1>
                <p className='px-10 lg:px-40 md:text-xl lg:text-2xl pt-5 pb-8 lg:pb-14'>This is the last collection, ninth. We'll mint <b>1.523 nft</b> unique piece of art.</p>
            </div>
            <img src={r_25_mobile} alt="" className='lg:hidden'/>
            <div className='bg-[#74FF77] text-center'>
                <h1 className='text-3xl px-8 lg:px-0 md:text-4xl lg:text-5xl font-black pt-8 lg:pt-12'>Q3 / 2027 <br />frank the sock the movie</h1>
                <p className='px-10 lg:px-40 md:text-xl lg:text-2xl pt-5'>Dreams come true.</p>
            </div>
            <div className='bg-[#74FF77] text-center'>
                <h1 className='text-3xl px-8 lg:px-0 md:text-4xl lg:text-5xl font-black pt-8 lg:pt-12'>Q4 / 2027 <br />videogame</h1>
                <p className='px-10 lg:px-40 md:text-xl lg:text-2xl pt-5 pb-8 lg:pb-14'>It will be released for mobile as well.</p>
            </div>
            <section className="hidden lg:block splide" aria-labelledby="carousel-heading">
                    <div className="splide__track">
                            <ul className="splide__list">
                                <li className="splide__slide"><img src={r_25_x2} alt="1" /></li>
                                <li className="splide__slide"><img src={r_25_x2} alt="1" /></li>
                            </ul>
                    </div>
                </section>
            <div className="relative">
                <span id="team" className='absolute bottom-20'></span>
            </div>
        </section>

     );
}

export default Roadmap;