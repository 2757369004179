import React from 'react';
import frankthesock from '../../assets/images/manifest_frank.png';

function Manifest() {
    return ( 
        <section className='md:h-screen bg-stone-200'>
                <div class="flex p-6 font-mono w-1/3 h-80 relative left-1/3 -translate-x-1/2 top-1/2 -translate-y-1/2 rounded-lg shadow-xl shadow-black">
                    <div class="flex-none w-48 mb-10 relative z-10 before:absolute before:top-1 before:left-1 before:w-full before:h-full before:bg-yellow-700 before:rounded-lg drop-shadow-lg">
                        <img src={frankthesock} alt="" class="absolute z-10 inset-0 w-full h-full object-cover rounded-lg" loading="lazy" />
                    </div>
                    <form class="flex-auto pl-6">
                        <div class="relative flex flex-wrap items-baseline pl-2 pb-6 before:bg-black before:absolute before:-top-6 before:bottom-0 before:-left-60 before:-right-6 before:rounded-lg">
                            <h1 class="relative w-full flex-none mb-2 text-2xl font-semibold text-white">
                                Hi! I'm Frank The Sock!
                            </h1>
                            <div class="relative uppercase text-amber-400">
                                Welcome!
                            </div>
                        </div>
                        <p class="pl-2 mt-4 leading-7 text-slate-500">
                            Let me introduce myself and my family...
                        </p>
                    </form>
                </div>
        </section>
    );
}

export default Manifest;