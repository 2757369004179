import SocialButtons from "./SocialButtons";

import raffaele from "../../assets/images/team/raffaele.jpeg"
import gaetano from "../../assets/images/team/gaetano.jpeg"
import daniel from "../../assets/images/team/daniel.png"
import michele from "../../assets/images/team/michele.jpg"
import tommaso from "../../assets/images/team/tommaso.jpeg"
import lussorio from "../../assets/images/team/lussorio.jpeg"
import gianluca from "../../assets/images/team/gianluca.jpeg"

function Team() {
    return ( 
        <section className="py-8 px-16" id="">
            <h1 className='font-black text-5xl text-center mb-10'>team</h1>
            <div className="flex flex-row flex-wrap h-full">
                    <div className="flex flex-col basis-full sm:basis-1/3 items-center pb-10 text-center">
                        <img className="mb-3 h-32 w-32 rounded-full shadow-lg" src={michele} alt="michele" />
                        <h5 className="mb-1 text-xl font-black">
                            Michele Ciro Franzese
                        </h5>
                        <h5 className="mb-1 text-xl font-black text-yellow-400">
                            founder
                        </h5>
                        <span className="text-sm text-gray-500">
                            Visual Artist, Fashion Marketer, Digital Enterpreneur, Vandal.
                        </span>
                        <span className="text-xs text-gray-400 w-11/12">
                        He also worked with MTV, Sony Music, Nike, Conde Nast, Mercedes, Universal and dozens international fashion brands.
                        </span>
                        <SocialButtons 
                            instagram={"https://www.instagram.com/rosso_mf/"}
                            website={"https://www.michelefranzese.com/"}
                            linkedin={"https://www.linkedin.com/in/michelecirofranzese/"}
                            twitter={"https://twitter.com/theredislove"}
                        />
                    </div>
                    <div className="flex flex-col basis-full sm:basis-1/3 items-center pb-10 text-center">
                        <img className="mb-3 h-32 w-32 rounded-full shadow-lg" src={tommaso} alt="tommaso" />
                        <h5 className="mb-1 text-xl font-black">
                            Tommaso Bennato
                        </h5>
                        <h5 className="mb-1 text-xl font-black text-yellow-400">
                            co-founder & art director
                        </h5>
                        <span className="text-sm text-gray-500">
                            Comic book artist and illustrator
                        </span>
                        <span className="text-xs text-gray-400 w-11/12">
                            {/* He also worked with: Marvel Usa (Hulk), Tokio Animation Gakuin, Godansha, Panini Comics, Star Comics.
                            He has his own comic “Inarime” published by Naver Corp (Korea). */}
                            He also worked with: Marvel (Hulk),
                            Kodansha, Star Comics.
                            And many french publisher as Clair de lune, Narratives and Delcourt.
                            He has his own comic “Inarime” published by Webtoon (South Korea).

                        </span>
                        <SocialButtons 
                            instagram={"https://www.instagram.com/t.bennato"}
                        />
                    </div>
                    <div className="flex flex-col basis-full sm:basis-1/3 items-center pb-10 text-center">
                        <img className="mb-3 h-32 w-32 rounded-full shadow-lg" src={lussorio} alt="lussorio" />
                        <h5 className="mb-1 text-xl font-black">
                            Lussorio Piras
                        </h5>
                        <h5 className="mb-1 text-xl font-black text-yellow-400">
                            project manager
                        </h5>
                        <span className="text-sm text-gray-500">
                            Music Label owner and talent manager.
                        </span>
                        <span className="text-xs text-gray-400 w-11/12">
                            He also worked with all of the music biz.
                        </span>
                        <SocialButtons
                            instagram={"https://www.instagram.com/lussoriopiras"}
                        />
                    </div>
                    <div className="flex flex-col basis-full sm:basis-1/4 items-center pb-10 text-center">
                        <img className="mb-3 h-32 w-32 rounded-full shadow-lg" src={gaetano} alt="gaetano" />
                        <h5 className="mb-1 text-xl font-black">
                            Gaetano Cuomo
                        </h5>
                        <h5 className="mb-1 text-xl font-black text-yellow-400">
                            cmo & advisor
                        </h5>
                        <span className="text-sm text-gray-500 w-11/12">
                            Experienced marketer, indie hacker, NFT advisor. 
                            Founder of Lost in Metaverse.
                        </span>
                        <SocialButtons
                            linkedin={"https://www.linkedin.com/in/gaetanocuomo/"}
                            website={"https://www.lostinmetaverse.club/"}
                        />
                    </div>
                    <div className="flex flex-col basis-full sm:basis-1/4 items-center pb-10 text-center">
                        <img className="mb-3 h-32 w-32 rounded-full shadow-lg" src={raffaele} alt="raffaele" />
                        <h5 className="mb-1 text-xl font-black">
                            Raffaele D'Errico
                        </h5>
                        <h5 className="mb-1 text-xl font-black text-yellow-400">
                            cto & advisor
                        </h5>
                        <span className="text-sm text-gray-500 w-11/12">
                            Full Stack Developer and Blockchain enthusiast. 
                            Co-Founder of Lost in Metaverse
                        </span>
                        <SocialButtons
                            github={"https://github.com/r4h01"}
                            linkedin={"https://www.linkedin.com/in/raffaele-derrico/"}
                            website={"https://www.lostinmetaverse.club/"}
                        />
                    </div>
                    <div className="flex flex-col basis-full sm:basis-1/4 items-center pb-10 text-center">
                        <img className="mb-3 h-32 w-32 rounded-full shadow-lg" src={daniel} alt="daniel" />
                        <h5 className="mb-1 text-xl font-black">
                            Daniel Namaki
                        </h5>
                        <h5 className="mb-1 text-xl font-black text-yellow-400">
                            front end dev & web3 adv
                        </h5>
                        <span className="text-sm text-gray-500 w-11/12">
                            GenZ Blockchain savvy. <br></br>
                            Also Full Stack & Blockchain Developer.
                        </span>
                        <SocialButtons
                            website={"https://daniel.namaki.it/"}
                            github={"https://github.com/NamaWho"}
                            linkedin={"https://www.linkedin.com/in/daniel-namaki-646bb8195"}
                            instagram={"https://www.instagram.com/danielnamaki/"}
                        />
                    </div>
                    <div className="flex flex-col basis-full sm:basis-1/4 items-center pb-10 text-center">
                        <img className="mb-3 h-32 w-32 rounded-full shadow-lg" src={gianluca} alt="gianluca" />
                        <h5 className="mb-1 text-xl font-black">
                            Gianluca Samblich
                        </h5>
                        <h5 className="mb-1 text-xl font-black text-yellow-400">
                            community & s.m.m.  
                        </h5>
                        <span className="text-sm text-gray-500 w-11/12">
                            Young talent in community management. 18 years old and already managing communities of millions of users.
                        </span>
                        <SocialButtons
                            linkedin={"https://www.linkedin.com/in/gianluca-samblich-577a67233/"}
                        />
                    </div>
                {/* </div> */}
            </div>
        </section>
     );
}

export default Team;