function PrivacyPolicy() {
    return ( 
        <section className="flex flex-col items-center justify-center py-16">
            <h1 className="text-gold font-black text-4xl">Privacy Policy</h1>
            <br/>
            <h2 className="black font-black text-4xl">Introduction</h2>
            <br/>
            <div>
            <p className="text-black text-xl">The privacy of visitors to our website is very important to us, so we are committed to protecting it.
            This privacy policy explains how we will process your personal information</p>
            <p className="text-black text-xl">In accordance with the terms of this policy, by giving your consent to the use of cookies on your first visit to our website, you allow us to use these cookies each time you visit our website</p>
        </div>
        <h2 className="black font-black text-4xl">Personal data collection</h2><br/>
        <div>
        <p className="text-black text-xl">
        Personal data of the following types may be collected, stored and used:<br/>
information about your device, including your IP address, location, type and version of your browser and operating system; <br/>
information about your visits and uses of this site, including the source web page (referral), the duration of the visit, the pages visited and the navigation paths within the website;<br/>

information generated during the use of our website, such as date and time, frequency and the circumstances of your use;<br/>

Before you share another person’s personal information with us, you must obtain that person’s consent to both the disclosure and the processing of such personal data in accordance with this policy.<br/>

        </p>
        </div>
        <h2 className="black font-black text-4xl">Cookies</h2><br/>
        <div>
        <p className="text-black text-xl">
        Our website uses cookies. <br/>

A cookie is a file containing an identifier (a string of letters and numbers) that is sent from a web server to a browser, and stored by them. <br/>

The identifier is then sent back to the server whenever that browser requests access to a page on that server. <br/>

Cookies can be "persistent" or "session": a persistent cookie will be stored by a browser and will remain valid until its expected expiration date, unless the user deletes it before;<br/>

a session cookie, however, will expire at the end of the user’s session, or when the browser is closed. <br/>

Generally, cookies do not contain any personally identifiable information about a user, however the personal information we store about you may be linked to information stored in, and obtained from, cookies. <br/>

(SELECT THE APPROPRIATE FORMULATION On our website, we only use session cookies / only persistent cookies / both session cookies and persistent cookies.)<br/>
Below, the names of the cookies we use on our website, as well as the purposes of their use:<br/>

we use Google Analytics and Adwords on our website to (INCLUDE ALL USES OF COOKIES ON YOUR SITE recognize a computer when a user visits the site / track users while browsing the site / enable the use of a shopping cart on the site / improve the usability of the site / analyze the use of the site / administer the site / prevent fraud and improve site security / customize the site depending on the user / send targeted advertising proposals that may be of particular interest to specific users / describe additional purposes);<br/>

Most browsers allow you to refuse cookies; for example:<br/>

In Internet Explorer (version 10) you can block cookies using special management settings that replace the basic configuration, by clicking on "Tools", "Internet Options", "Privacy" and then "Advanced";<br/>

in Firefox (version 69) you can block all cookies by clicking on the menu button at the top right and selecting "Options"; in the section "Privacy and security", "Content block", select "Custom"; here select the box "Cookies" and in the relevant drop-down menu choose "All cookies";<br/>

In Chrome (version 77) you can block all cookies by accessing the top right menu and clicking on "Settings"; on the left, select "Advanced", "Privacy and security", then click on "Site settings", "Cookies and site data"; finally, disable the option "Allow sites to save and read cookie data". <br/>

Blocking all cookies will have a negative impact on the usability of many websites. By blocking cookies, you will not be able to use all the functions of our website. <br/>

You can delete cookies already stored on your computer; for example : <br/>

in Internet Explorer (version 10), you must manually delete the cookie files (instructions for doing so can be found on http://support.microsoft.com/kb/278835);<br/>

In Firefox (version 69), you can delete all cookies by clicking on the menu button at the top right and selecting "Options"; in the section "Privacy and security", "Cookies and website data", click on the button "Delete data..." ;<br/>

check that the appropriate box is checked, then press the button "Delete";<br/>

In Chrome (version 77), you can delete all cookies by accessing the top right menu and clicking on "Other tools", "Clear browsing data"; here, in the drop-down menu "Time interval", select "All";<br/>

check that the box "Cookies and other site data" is selected
        </p>
        </div>
            </section>
    );
}

export default PrivacyPolicy;