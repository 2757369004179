import { Footer } from "flowbite-react";
import discord from "../../assets/images/social/2_discord_menu.png";
import instagram from "../../assets/images/social/3_ig_menu.png";
import facebook from "../../assets/images/social/4_fb_menu.png";
import twitter from "../../assets/images/social/5_tw_menu.png";

function FooterPage() {
    return (
        <Footer container={true}>
            <div className="w-full text-center">
                <div className="w-full flex flex-col items-center justify-center">
                    <div className="my-10">
                        <h2 className="text-2xl md:text-3xl text-left my-auto">
                            <span className="block mx-auto text-center">
                                Hey, if you get here it means you like us. <br /> Jump on board and join the <b>FAMILY</b>!
                            </span>
                        </h2>
                        <div className="my-auto">
                            {/* <button type="button" className="block text-white bg-lgbt-flag bg-cover focus:ring-4 focus:outline-none focus:ring-pink-200 dark:focus:ring-pink-800 font-bold text-3xl rounded-full px-16 py-5 text-center mb-2 mt-4 uppercase mx-auto">Join the family</button> */}
                            <button type="button" className="text-white bg-lgbt-flag bg-cover hover:bg-lgbt-flag-reverse font-bold text-xl rounded-full px-2 py-2 text-center mt-5 block mx-auto">
                                <a href="https://discord.gg/EmnmSnmVXr" target="_blank" rel="noreferrer">
                                    <span className='bg-white block text-gray-900 rounded-full px-8 md:px-16 py-4 text-2xl md:text-3xl'>
                                        join the family
                                    </span>
                                </a>
                            </button>
                        </div>
                    </div>

                    {/* <div className="md:w-1/2 mt-3 mb-8">
                        <p className="mb-5 md:mb-5">Subscribe to our <b className="">mailing list</b></p>

                        <form action="" method="post" id="mailing-form" name="mailing-form">
                            <div class="flex items-end mb-3">
                                <div class="relative w-full mr-3 mailing-form-group">
                                    <input type="email" name="floating_email" id="floating_email" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
                                    <label for="floating_email" class="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 origin-[0] left-0 peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Email address</label>
                                </div>
                                <div class="mailing-form-actions">
                                    <input type="submit" value="subscribe" class="cursor-pointer text-white bg-blue-700 hover:bg-blue-800 focus:ring-2 focus:ring-blue-300 font-medium rounded-full text-sm px-5 py-2.5 text-center" name="member[subscribe]" id="member_submit" />
                                </div>
                            </div>
                        </form>
                    </div> */}
                    
                    <Footer.LinkGroup>
                        <Footer.Link href="https://www.privacypolicies.com/live/b97bef7e-a369-467d-99c5-d6bb00e73606">
                            Privacy Policy
                        </Footer.Link>
                    </Footer.LinkGroup>
                    <div className="flex gap-x-4 mt-4">
                        <div className="flex ">
                            <a href="https://discord.gg/EmnmSnmVXr"><img src={discord} alt="" className="h-5 px-2"/></a>
                            <a href="https://www.instagram.com/thesocksfamily/"><img src={instagram} alt="" className="h-5 px-2"/></a>
                            {/* <a href=""><img src={facebook} alt="" className="h-5 px-2"/></a> */}
                            <a href="https://twitter.com/thesocksfamily"><img src={twitter} alt="" className="h-5 px-2"/></a>
                        </div>
                    </div>
                </div>
                <Footer.Divider />
                <Footer.Copyright
                    by="TheSocksFamily"
                    year={2022}
                />
            </div>
        </Footer>
     );
}

export default FooterPage;