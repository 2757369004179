import { elastic as Menu } from "react-burger-menu";
import { scrollTo } from "../../utils/scrollTo";
import logo from "../../assets/images/1_logo_menu.png";
import { useState } from "react";

import discord from "../../assets/images/social/2_discord_menu.png";
import instagram from "../../assets/images/social/3_ig_menu.png";
import facebook from "../../assets/images/social/4_fb_menu.png";
import twitter from "../../assets/images/social/5_tw_menu.png";

var styles = {
    bmBurgerButton: {
        width: '30px',
        height: '30px',
        position: "fixed",
        top: '-30px',
        left: '-30px'
    },
    bmBurgerBars: {
    //   background: '#373a47'
      background: 'transparent'
    },
    bmBurgerBarsHover: {
      background: '#a90000'
    },
    bmCrossButton: {
      height: '24px',
      width: '24px'
    },
    bmCross: {
      background: 'black'
    },
    bmMenuWrap: {
      position: 'fixed',
      height: '100%'
    },
    bmMenu: {
      background: 'white',
      padding: '0em 1.5em 0',
      fontSize: '1.15em',
      width: '240px'
    },
    bmMorphShape: {
      fill: 'white'
    },
    bmItemList: {
      color: 'black',
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-around",
      padding: '4rem 0.8em'
    },
    bmItem: {
      display: 'inline-block',
    },
    bmOverlay: {
      background: 'rgba(0, 0, 0, 0.3)'
    }
  }

  
  
function NavbarMobile() {
      
    let [isOpen, setOpen] = useState(0);
      
      
    let toggleOpen = (props) => {
        setOpen(!isOpen);

        if(props){
            scrollTo(props);
        }
    }

    return ( 
        <>
        <div className="bg-white px-4 py-5 fixed w-full z-30 top-0 left-0 lg:hidden flex justify-between">
            
            <div className="w-[25px]"></div>
            <span className="flex items-center cursor-pointer">
                <img src={logo} alt="" className='mr-3 w-52 md:w-72'/>
            </span>
            <div className="w-[25px] h-[18px] flex flex-col justify-around my-auto outline-none" onClick={() => toggleOpen()}> 
                <span className="block bg-black h-[12%] w-full"></span>
                <span className="block bg-black h-[12%] w-full"></span>
                <span className="block bg-black h-[12%] w-full"></span>
            </div>
        </div>
        <Menu styles={styles} isOpen={isOpen} onOpen={() => toggleOpen()} onClose={() => toggleOpen()}>
            <span className="menu-item" onClick={() => toggleOpen("project")} style={{outline: 'none'}}> manifesto</span>
            <span className="menu-item" onClick={() => toggleOpen("features")}>features</span>
            <span className="menu-item" onClick={() => toggleOpen("family")}> family</span>
            <span className="menu-item" onClick={() => toggleOpen("roadmap")}> roadmap</span>
            <span className="menu-item" onClick={() => toggleOpen("team")}> team</span>
            <div className="flex-row justify-around" style={{display: 'flex'}}>
                <a href="https://discord.gg/EmnmSnmVXr"><img src={discord} alt="" className="h-6 px-1"/></a>
                <a href="https://www.instagram.com/thesocksfamily/"><img src={instagram} alt="" className="h-6 px-1"/></a>
                <a href=""><img src={facebook} alt="" className="h-6 px-1"/></a>
                <a href="https://twitter.com/thesocksfamily"><img src={twitter} alt="" className="h-6 px-1"/></a>
            </div>
        </Menu>
        </>
     );
}

export default NavbarMobile;