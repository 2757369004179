// import {useState} from 'react'
import "./App.css"
import {
  BrowserRouter,
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom"
import Manifest from "./components/Manifest/Manifest"
import Roadmap from "./components/Roadmap/Roadmap"
import Features from "./components/Features/Features"
import Family from "./components/Family/Family"
import NavbarSection from "./components/Navbar/Navbar"
import Hero from "./components/Hero/Hero"
import TestMap from "./components/Roadmap/TestMap"
import Team from "./components/Team/Team"
import FooterPage from "./components/Footer/FooterPage"
import FrankCapsuleCollection from "./components/FrankCapsuleCollection/FrankCapsuleCollection"
import Project from "./components/Project/Project"
import React, { useEffect } from "react"
import Splide from "@splidejs/splide"
import "@splidejs/react-splide/css"
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll"
import NavbarMobile from "./components/Navbar/NavbarMobile"

function App() {
  // const [accounts, setAccounts] = useState([]);

  const [index, setIndex] = React.useState(0)

  React.useEffect(() => {
    const intervalId = setInterval(() => setIndex((index) => index + 1), 1000)
    return () => clearTimeout(intervalId)
  }, [])

  React.useEffect(() => {
    let elms = document.getElementsByClassName("splide")

    for (var i = 0; i < elms.length; i++) {
      new Splide(elms[i], {
        type: "loop",
        pagination: false,
        arrows: false,
        pauseOnHover: false,
        autoScroll: {
          speed: 1,
          pauseOnHover: false,
        },
      }).mount({ AutoScroll })
    }

    // new Splide( '.splide', {
    //                             type: 'loop',
    //                             pagination: false,
    //                             arrows: false,
    //                             pauseOnHover: false,
    //                             autoScroll: {
    //                                 speed: 1
    //                             }
    //                         } ).mount({AutoScroll});
  }, [])

  return (
    <>
      {/* <Navbar accounts={accounts} setAccounts={setAccounts} /> */}
      {/* <Sidebar/>
      <Navbar/> */}
      <NavbarMobile />
      <NavbarSection />
      <Hero index={index} />
      <Project index={index} />
      <Features />
      <Family />
      <Roadmap />
      {/* <TestMap /> */}
      {/* <FrankCapsuleCollection /> */}
      <Team />
      <FooterPage />
      {/* <Roadmap /> */}
      {/* <Manifest/> */}
    </>
  )
}

export default App
