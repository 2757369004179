export const scrollTo = (props = null) => {

    let topOffset = 0;

    if(props !== null){
        // let height = document.getElementById(props).offsetTop;
        let height = window.scrollY + document.getElementById(props).getBoundingClientRect().top;
        topOffset = height;
        // topOffset += window.innerHeight;
    }

    window.scrollTo({
        top: topOffset,
        behavior: 'smooth',
    })

}