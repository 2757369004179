import React from 'react';

function FeatureCard(props) {

    let {title, desc, color} = props;

    return ( 
        //shadow-[0px_0px_10px_3px_rgba(255,255,255,1)]
        // md:h-96 lg:h-72 xl:h-64
        <div className="bg-white py-4 lg:p-4 justify-center text-center h-40 lg:h-96 lg:h-72 xl:h-64 drop-shadow-lg" >
            <h1 className='font-black text-lg md:text-xl lg:text-[1.7rem] 2xl:text-[2rem] lg:py-5 2xl:py-8'>{title}</h1>
            {/* <div className='flex h-64 lg:h-52 xl:h-40'> */}
            <p className='text-sm md:text-base lg:text-[1.3rem] leading-5 lg:leading-7 px-6 lg:px-12 lg:mt-2 2xl:mt-5'>{desc}</p>
            {/* </div> */}
        </div> 
    );
}

export default FeatureCard;