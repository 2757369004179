import proj1 from "../../assets/images/manifesto/9_manifesto_1.jpg";
import proj2 from "../../assets/images/manifesto/9_manifesto_2.jpg";
import proj3 from "../../assets/images/manifesto/9_manifesto_3.jpg";

const IMAGES = [
    proj1,
    proj2,
    proj3
]

function Project(props) {
    const {index} = props;

    return ( 
        <section className="py-8 lg:py-16 bg-blur bg-cover">
            <div className="lg:flex h-full">
                <div className="text-center lg:text-left lg:my-auto lg:w-3/5 lg:pl-20 lg:pr-10">
                    <h1 className="font-black text-3xl md:text-4xl lg:text-6xl pb-6 lg:pb-6 mx-auto w-11/12 lg:w-full"> manifesto</h1>
                    <p className="px-10 lg:px-0 leading-5 lg:leading-7 text-lg md:text-xl lg:text-2xl xl:text-3xl 2xl:text-4xl text-black mb-8 lg:mb-0"> 
                        Our mission is to create a large family, not just a community. In The Socks Family, everyone is welcome. <br/><br/>
                        We want the Metaverse to be more diverse and inclusive.<br/><br/>
                        We’d like to celebrate people and ideas.<br/>
                        No diversity here, <b>just a family</b>.</p>
                </div>
                <div className="lg:h-auto lg:p-2 lg:w-2/5 lg:flex">
                        <img src={proj1} alt="" className={"my-auto lg:pl-10 lg:pr-20 w-2/3 md:w-2/5 lg:w-4/5 mx-auto " + (((index%IMAGES.length)===0) ? "" : "hidden")} />
                        <img src={proj2} alt="" className={"my-auto lg:pl-10 lg:pr-20 w-2/3 md:w-2/5 lg:w-4/5 mx-auto " + (((index%IMAGES.length)===1) ? "" : "hidden")} />
                        <img src={proj3} alt="" className={"my-auto lg:pl-10 lg:pr-20 w-2/3 md:w-2/5 lg:w-4/5 mx-auto " + (((index%IMAGES.length)===2) ? "" : "hidden")} />
                </div>
            </div>
            <span id="features" className=""></span>
        </section>
    );
}

export default Project;