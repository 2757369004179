import React from 'react';
import frank from "../../assets/images/family/12_family_frank.png";
import mary from "../../assets/images/family/13_family_mary.png";
import benny from "../../assets/images/family/14_family_benny.png";
import gina from "../../assets/images/family/15_family_gina.png";

function Family() {

    return (         
        <section className="" id="">
            <div className='bg-[#01FF4A]'>
                <div className='grid grid-cols-1 lg:grid-cols-4 lg:gap-8'>
                    <div className='lg:col-span-2 lg:my-auto lg:pl-20 2xl:pl-32 pt-8 lg:py-16'>
                        <h1 className='text-center lg:text-left font-black text-3xl md:text-4xl lg:text-5xl mb-5'>the family: <br/> frank the sock</h1>
                        <p className='text-center lg:text-left md:text-xl lg:text-3xl px-10 lg:px-0'>
                        <span className='italic'>"I'm Frank, and I'm Straight, Gay, Fluid, Trans, Bisexual, Asexual, White, Black, Brown, Asian, Skinny, Fat, Burned, Tattooed, Dead, a Ghost, an Android, a Zombie, a Monster, everything I want to be."</span>
                            <br /><br />
                            <b>Frank</b> (Dad) is part of a family of four. The members of the family are <b>Mary</b> (Mum), <b>Benny</b> (Son), <b>Gina</b> (Daughter). They are <b>The Sock's Family</b>.
                            <br /><br />
                            <b>Frank The Sock</b> is the main character of the family. He has 3 different nft collections on his behalf.
                            He is a strange and passionate man with a mysterious past in the <b>music world</b>.
                            The story goes that he was the one who launched Drake, Ye and Dr.Dre.
                            Or at least, he always tells this story.
                        </p>
                    </div>
                    <div className='flex mx-auto w-4/5 md:w-3/5 lg:w-full lg:pr-20 lg:col-span-2 lg:items-end'>
                        <img src={frank} alt="" className='lg:h-[96%]'/>
                    </div>
                </div>
            </div>
            <div className='bg-[#FFA1F4]'>
            <div className='grid grid-cols-1 lg:grid-cols-4 lg:gap-8'>
                    <div className='lg:col-span-2 lg:my-auto lg:pl-20 2xl:pl-32 pt-8 lg:py-16'>
                        <h1 className='text-center lg:text-left font-black text-3xl md:text-4xl lg:text-5xl mb-5'>mary the sock</h1>
                        <p className='text-center lg:text-left md:text-xl lg:text-3xl px-10 lg:px-0'>
                        She <b>loves</b> Frank since she left the college.
                        <br /><br />
                        She <b>loves</b> other women since she understood that love is all.
                        <br /><br />
                        She <b>loves</b> her two children.
                        <br /><br />
                        She is Mary, she doesn't care too much about bias.
                        <br /><br />
                        With a background as a hip hop DJ, Mary is now totally into fashion and streetwear world.
                        <br /><br />
                        One day she would like to launch her own brand of <b>eco-friendly socks</b>.
                        </p>
                    </div>
                    <div className='flex mx-auto justify-center w-2/3 md:w-3/5 lg:w-full lg:pr-20 lg:col-span-2'>
                        <img src={mary} alt="" className=''/>

                    </div>   
            </div>
            </div>
            <div className='bg-[#1DBCFF]'>
            <div className='grid grid-cols-1 lg:grid-cols-4 lg:gap-8'>
                    <div className='lg:col-span-2 lg:my-auto lg:pl-20 2xl:pl-32 pt-8 lg:py-16'>
                        <h1 className='text-center lg:text-left font-black text-3xl md:text-4xl lg:text-5xl mb-5'>benny the sock</h1>
                        <p className='text-center lg:text-left md:text-xl lg:text-3xl px-10 lg:px-0'>
                        He is Frank's son, he is Mary's son, he his son of two dads, he is son of two mums. 
                        <br /><br />
                        He is a screenager. He is a little basketball genius (he says).
                        <br /><br />
                        A 3-time backcourt MVP, he dreams of playing in the NBA in jersey number 23.
                        <br /><br />
                        The kid has a huge dream, and a pretty good jump shot, we'll see.
                        </p>
                    </div>
                    <div className='flex justify-center mx-auto w-2/3 md:w-2/5 lg:w-full lg:col-span-2'>
                        <img src={benny} alt="" className=''/>
                    </div>
                    </div>
                
            </div>
            <div className='bg-[#FECF4E] relative'>
            <div className='grid grid-cols-1 lg:grid-cols-4 lg:gap-8'>
                    <div className='lg:col-span-2 lg:my-auto lg:pl-20 2xl:pl-32 pt-8 lg:py-16'>
                        <h1 className='text-center lg:text-left font-black text-3xl md:text-4xl lg:text-5xl mb-5'>gina the sock</h1>
                        <p className='text-center lg:text-left md:text-xl lg:text-3xl px-10 lg:px-0'>
                        She is the latest arrival of the family, she is a gift (check the roadmap).
                        <br /><br />
                        One day she would like to become the first female president of Mars and make diplomatic agreements with all species in the galaxy.
                        <br /><br />
                        For now, she has already conquered the first major milestone of her life: using the potty by herself!
                        <br /><br />
                        She looks forward to a bright future.
                        </p>
                    </div>
                    <div className='flex justify-center mx-auto w-2/3 md:w-2/5 lg:w-full lg:col-span-2'>
                        <img src={gina} alt="" className=''/>
                    </div>
                
                </div>
                <span id="roadmap" className="absolute bottom-20"></span>
            </div>
        </section>
    );
}

export default Family;