import { Navbar, Button } from "flowbite-react";
import { NavbarBrand } from "flowbite-react/lib/esm/components/Navbar/NavbarBrand";
import { useState } from "react";
import logo from "../../assets/images/1_logo_menu.png";
import { scrollTo } from "../../utils/scrollTo";

import discord from "../../assets/images/social/2_discord_menu.png";
import instagram from "../../assets/images/social/3_ig_menu.png";
import facebook from "../../assets/images/social/4_fb_menu.png";
import twitter from "../../assets/images/social/5_tw_menu.png";
import youtube from "../../assets/images/social/6_yt_menu.png";
import tiktok from "../../assets/images/social/7_tk_menu.png";

const navigation = [
  { name: 'manifesto', href: 'project'},
  { name: 'features', href: 'features'},
  { name: 'family', href: 'family'},
  { name: 'roadmap', href: 'roadmap'},
  { name: 'team', href: 'team'},
]

export default function NavbarSection() {

  return (

    <>
    <div className="hidden lg:flex lg:fixed lg:bg-white lg:px-20 2xl:px-32 lg:py-6 lg:justify-between lg:content-center lg:w-full lg:z-30 lg:top-0 lg:left-0 lg:h-[13vh] 2xl:h-[10vh]" id="">
        <span className="flex items-center cursor-pointer" onClick={() => scrollTo()}>
          <img src={logo} alt="" className='mr-3 lg:w-[18rem]'/>
        </span>

        <div className="flex">
          {navigation.map((item, index) => 
                  <span
                    className={'block font-[600] cursor-pointer hover:text-gold hover:underline-offset-8 hover:underline text-center text-gray-700 rounded md:bg-transparent lg:px-2 my-auto lg:text-xl'}
                    onClick={() => scrollTo(item.href)}
                    >
                    {item.name}
                  </span>
              )
            }
        </div>

        <div className="lg:w-[18rem] lg:flex justify-end content-center">
          {/* <a href="https://discord.gg/jnJmGGURr4" className="my-auto"><img src={discord} alt="" className="h-5 px-2"/></a> */}
          <a href="https://discord.gg/EmnmSnmVXr" className="my-auto"><img src={discord} alt="" className="h-5 px-2"/></a>
          <a href="https://www.instagram.com/thesocksfamily/" className="my-auto"><img src={instagram} alt="" className="h-5 px-2"/></a>
          {/* <a href="https://facebook.com" className="my-auto"><img src={facebook} alt="" className="h-5 px-2"/></a> */}
          <a href="https://twitter.com/thesocksfamily" className="my-auto"><img src={twitter} alt="" className="h-5 px-2"/></a>
        </div>
    </div>





    {/* // <div className="bg-white px-2 sm:px-20 2xl:px-32 py-2.5 lg:py-6 fixed w-full z-30 top-0 left-0" id=""> */}
    {/* <div className="bg-white lg:px-20 2xl:px-32 lg:py-6 hidden lg:flex lg:justify-between lg:content-center fixed w-full z-30 top-0 left-0 lg:h-[13vh]" id="">
      <Navbar
        fluid={true}
        rounded={true}
        style={{padding:0}}
        >
        <Navbar.Brand onClick={() => scrollTo()}>
          <span className="flex items-center cursor-pointer">
            <img src={logo} alt="" className='mr-3 w-60 md:max-w-xs'/>
          </span>
        </Navbar.Brand>
        <div className="flex md:order-2 xl:w-72 justify-end">
          <div className="hidden lg:flex ">
            <a href="https://discord.gg/jnJmGGURr4 "><img src={discord} alt="" className="h-5 px-2"/></a>
            <a href="https://www.instagram.com/thesocksfamily/"><img src={instagram} alt="" className="h-5 px-2"/></a>
            <a href=""><img src={facebook} alt="" className="h-5 px-2"/></a>
            <a href="https://twitter.com/thesocksfamily"><img src={twitter} alt="" className="h-5 px-2"/></a>
          </div>
          <Navbar.Toggle 
          />
        </div>
        <Navbar.Collapse
        >
          {navigation.map((item, index) => 
              <Navbar.Link
              key={index}
              // href={item.href}
              
              aria-current={item.current ? 'page' : undefined}
              >
                <span
                  className={'block font-[600] cursor-pointer hover:text-gold hover:underline-offset-8 hover:underline text-center text-gray-700 pr-4 pl-3 py-2 rounded md:bg-transparent md:p-0 my-auto md:text-xl'}
                  // onClick={handleLinkClick}
                  onClick={() => scrollTo(item.href)}
                  >
                  {item.name}
                </span>
              </Navbar.Link>
            )
            
          }

        </Navbar.Collapse>
      </Navbar>
    </div> */}
    </>

)

}
