import { AnimationOnScroll } from "react-animation-on-scroll";
import FeatureCard from "./FeatureCard";

import gadgets from "../../assets/images/features/9_features_1.jpg";
import banner1 from "../../assets/images/features/10_stacco_featurer_x1.jpg";
import banner2 from "../../assets/images/features/10_stacco_featurer_x2.jpg";

import banner1_mobile from "../../assets/images/features/the_socks_family_franks.jpg";
import gadgets_mobile from "../../assets/images/features/the_socks_family_garments.jpg";

import React from 'react';

// import Splide from '@splidejs/splide';
// import '@splidejs/react-splide/css';
// import { AutoScroll } from '@splidejs/splide-extension-auto-scroll';

function Features() {

    return ( 
        <section className="lg:pt-16" id="">
            <div className="lg:px-20">
                <h1 className="font-black text-3xl md:text-4xl lg:text-5xl text-center mt-8 lg:mt-0 mb-5">
                    why join the family?
                </h1>
                <p className="text-lg md:text-xl lg:text-2xl leading-5 lg:leading-8 text-center px-10 lg:px-0">
                        Right, let's get to the interesting stuff.
                        We not only want to create the largest family in the Metaverse, but we want to create the most irreverent <b>brand</b> in the world. 
                        NFT, socks, mugs, video games, movies, comics, your grandmother's underwear. <b>We want to stick our logo on anything.</b>
                </p>
                <div className="my-5">
                    <img src={gadgets} alt="" className="hidden lg:block "/>
                    <img src={gadgets_mobile} alt="" className="lg:hidden md:w-4/5 md:mx-auto"/>
                </div>
                <h1 className="font-black text-3xl md:text-4xl lg:text-5xl text-center mt-8 mb-2 lg:mt-10 lg:mb-5">
                    ooook...but why?
                </h1>
                <p className="text-lg md:text-xl lg:text-2xl leading-5 lg:leading-8 text-center px-10 lg:px-0">
                Because ours is a controversial, hilarious, subversive, satirical, diversionary in inclusiveness, comical, morally honorable, incredibly intelligent family. Just a modern family, which is why we want to conquer the world launching this ambitious nft project, hoping that all the families in the world could become adorably freak like our Sockers.
                </p>
                <button type="button" className="bg-lgbt-flag bg-cover hover:bg-lgbt-flag-reverse focus:ring-4 focus:outline-none font-bold text-xl rounded-full px-1 py-1 text-center my-10 block mx-auto">
                    <a href="https://discord.gg/EmnmSnmVXr" target="_blank" rel="noreferrer">
                        <span className='bg-white block rounded-full px-8 lg:px-16 py-2.5'>
                            join the family
                        </span>
                    </a>
                </button>
            </div>
            <img src={banner1_mobile} alt="" className="lg:hidden"/>
            <section class="hidden lg:block splide" aria-labelledby="carousel-heading">
                <div class="splide__track">
                        <ul class="splide__list">
                            <li class="splide__slide"><img src={banner1} alt="1" /></li>
                            <li class="splide__slide"><img src={banner1} alt="1" /></li>
                        </ul>
                </div>
            </section>
            <div className="bg-blur2 bg-cover lg:h-auto 2xl:h-auto">
                <div className="flex flex-column items-center h-4/5 pt-8 lg:pt-20">
                    <div className="mx-auto w-full px-5 md:px-32 lg:px-20 grid lg:grid-cols-2 gap-y-6 lg:gap-20 2xl:gap-16">
                        <AnimationOnScroll animateIn="animate__fadeIn">
                            <FeatureCard color="border-green-500" title="a skilled (and doxxed) team." desc="We are professionals with years of experience in a lot of buzz words. And the art was developed by a Marvel cartoonist. (cool, isn't it?)"/>
                        </AnimationOnScroll>
                        <AnimationOnScroll animateIn="animate__fadeIn">
                            <FeatureCard color="border-blue-800" title="the artwork is freaking cool" desc="Let's face it, a hand with a sock on it. Keep your dark depressed kid stuff, we have the really cool stuff here."/>
                        </AnimationOnScroll>
                        <AnimationOnScroll animateIn="animate__fadeIn">
                            <FeatureCard color="border-red-700" title="from digital to your uncle's feet" desc="We don't want to limit ourselves to NFTs, we want to enter the real world with clothing collections.  We're going to start with socks. No one wants cold feet, right?"/>
                        </AnimationOnScroll>
                        <AnimationOnScroll animateIn="animate__fadeIn">
                            <FeatureCard color="border-purple-800" title="from real to digital again" desc="Yes, we like to hop around between worlds. What comes after the socks? The TV series, simple as that. Simpsons, you are warned, there will be only one Family left."/>
                        </AnimationOnScroll>
                    </div> 
                </div>
                <div className="w-10/12 mx-auto py-8 lg:py-20">
                    <p className="text-center text-xl lg:text-4xl">
                        ...and lots of other cool stuff, but for that there is the <br/><a href="#roadmap" className="hover:underline hover:decoration-white hover:text-white hover:underline-offset-4 text-4xl md:text-5xl"><b>roadmap</b></a>
                    </p>
                    <span id="family" className=""></span>
                </div>
            </div>


            {/* <div className="w-11/12 md:w-9/12 md:max-w-[75%] px-10 mx-auto mb-10">
                <h1 className="font-black text-gold text-3xl uppercase text-center">
                    Why join the Family?
                </h1>
                <p className="text-2xl text-center text-white">
                    Right, let's get to the interesting stuff.
                    We not only want to create the largest family in the Metaverse, but we want to create the most irreverent Brand in the world. 
                    NFT, socks, mugs, video games, movies, comics, your grandmother's underwear. We want to stick our logo on anything.
                </p>
                <h1 className="font-black text-gold text-3xl uppercase mt-5 text-center">
                    Ooook...but Why?
                </h1>
                <p className="text-2xl text-center text-white">
                    Because ours is a controversial, hilarious, subversive, satirical, sassy, diversionary in inclusiveness, 
                    comical, politically incorrect, morally despicable, morally honorable, incredibly intelligent family.
                    Just a modern family, which is why we want to conquer the world launch this ambitious NFT project. 
                </p>
                <div className="my-10">
                    <button type="button" className="text-white bg-lgbt-flag bg-cover hover:bg-lgbt-flag-reverse focus:ring-4 focus:outline-none focus:ring-pink-200 font-bold text-xl rounded-full px-1 py-1 text-center mb-2 mt-4 uppercase block mx-auto">
                        <span className='bg-gray-900 block text-white rounded-full px-8 md:px-16 py-2.5'>
                            Join the family
                        </span>
                    </button>
                </div>
            </div> */}
            {/* <div className="flex flex-column items-center">
                <div className="mx-auto w-full px-10 md:max-w-[75%] grid md:grid-cols-2 gap-2 md:gap-4 gap-x-8">
                    <AnimationOnScroll animateIn="animate__fadeIn">
                        <FeatureCard color="border-green-500" title="A Baddass (and Doxxed) Team." desc="Although we look like a bunch of decelebrates, we are professionals with years of experience in a lot of buzz words. And the art was developed by a Marvel cartoonist. (cool, isn't it?)"/>
                    </AnimationOnScroll>
                    <AnimationOnScroll animateIn="animate__fadeIn">
                        <FeatureCard color="border-blue-800" title="The artwork is freaking cool" desc="Let's face it, a hand with a sock on it. Keep your dark depressed kid stuff, we have the really cool stuff here."/>
                    </AnimationOnScroll>
                    <AnimationOnScroll animateIn="animate__fadeIn">
                        <FeatureCard color="border-red-700" title="From digital to your uncle's feet" desc="We don't want to limit ourselves to NFTs, we want to enter the real world with clothing collections.  We're going to start with socks. No one wants cold feet, right?"/>
                    </AnimationOnScroll>
                    <AnimationOnScroll animateIn="animate__fadeIn">
                        <FeatureCard color="border-purple-800" title="From real to digital again" desc="Yes, we like to hop around between worlds. What comes after the socks? The TV series, simple as that. Simpsons, you are warned, there will be only one Family left."/>
                    </AnimationOnScroll>
                </div> 
            </div>
            <div className="w-10/12 mx-auto mt-10 md:mt-16">
                <p className="text-center text-4xl text-white">
                    ...and lots of other cool stuff, but for that there is the <a href="#roadmap" className="hover:underline hover:decoration-gold hover:underline-offset-4"><b className="text-gold">ROADMAP</b></a>.
                </p>
                <span id="family" className=""></span>
            </div>
             */}
        </section>
    );
}

export default Features;