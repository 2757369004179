import React from 'react';
import artworks from "../../assets/images/artworks.gif";
import TextTransition, { presets } from "react-text-transition";

import slider1 from "../../assets/images/slider/8_slider_1.png";
import slider2 from "../../assets/images/slider/8_slider_3.png";
import slider3 from "../../assets/images/slider/8_slider_4.png";
import slider4 from "../../assets/images/slider/8_slider_2.png";
import slider5 from "../../assets/images/slider/8_slider_5.png";

const SLIDERS = [
    slider1, 
    slider2,
    slider3,
    slider4,
    slider5
];

const BACKGROUNDS = [
    "bg-[#B147FF]",
    "bg-[#01FFD9]",
    "bg-[#1ACEFF]",
    "bg-[#FF47CD]",
    "bg-[#00C100]",
];

const WORDS = [
    "black",
    "lesbian",
    "a zombie",
    "bisexual",
    "still reading?"
  ];

const COLORS = [
    "text-[#A1E700]",
    "text-[#B70390]",
    "text-[#FFFFE1]",
    "text-[#FDFEE1]",
    "text-[#00FFAC]",
];

function Hero(props) {

    const {index} = props;

    return ( 
        <section className={"lg:h-screen " + BACKGROUNDS[index % BACKGROUNDS.length]} id="hero">
            <div className='lg:h-[13vh] 2xl:h-[10vh]'></div>
            <div className='flex lg:h-[87vh] 2xl:h-[90vh]'>
                {/* <div className="lg:grid lg:grid-cols-2"> */}
                <div className="lg:flex lg:flex-row">
                    <div className="my-auto lg:w-2/5 lg:pl-20 2xl:pl-32">
                        <h1 className="text-center font-black text-3xl md:text-4xl g:text-6xl mt-24 lg:text-left md:mt-32 lg:mt-10 lg:mb-5">
                            the first family <br />of the metaverse
                        </h1>
                        <p className="text-lg leading-5 lg:leading-7 px-6 lg:px-0 md:text-xl lg:text-3xl lg:w-11/12 text-center lg:text-left mt-6 lg:mt-5 lg:mt-10 mx-3 lg:mx-0">
                            From the pen of a Marvel, Kodansha, Webtoon, Delcourt (and many others) cartoonist comes the most 
                             <b> inclusive</b>, irreverent, weird, politically incorrect family in the <b>nft</b> world.
                        </p>
                        <div className="text-lg md:text-xl lg:text-3xl mt-5 lg:mt-10 mb-5 lg:mb-0 text-center lg:text-left">
                        Whether you are  
                            <span className={'flex lg:inline-block justify-center lg:justify-none lg:mx-4 font-black ' + COLORS[index%COLORS.length]}>    
                                <TextTransition springConfig={presets.wobbly}>
                                    {WORDS[index % WORDS.length]}
                                </TextTransition>
                            </span>
                            <br className='hidden lg:block' />
                            you are welcome!
                        </div>
                        <span id="project" className=''></span>
                    </div>
                    <div className='lg:my-auto lg:w-3/5 lg:max-w-[60%] flex justify-center lg:justify-end lg:relative h-[40vh] lg:h-full'>
                            <img src={slider1} alt="" className={'lg:h-full ' + (((index%SLIDERS.length)===0) ? "" : "hidden")} />
                            <img src={slider2} alt="" className={'lg:h-full ' + (((index%SLIDERS.length)===1) ? "" : "hidden")}  />
                            <img src={slider3} alt="" className={'lg:h-full ' + (((index%SLIDERS.length)===2) ? "" : "hidden")}  />
                            <img src={slider4} alt="" className={'lg:h-full ' + (((index%SLIDERS.length)===3) ? "" : "hidden")}  />
                            <img src={slider5} alt="" className={'lg:h-full ' + (((index%SLIDERS.length)===4) ? "" : "hidden")} />
                    </div>
                </div>
            </div>
                
        </section>
     );
}

export default Hero;