function FrankCapsuleCollection() {
    return ( 
        <section className="h-screen flex bg-white">
            <div className="mx-auto my-auto">
                <h1 className="text-5xl text-gray-900 text-center"><b>...FRANK THE SOCK: <span className="text-gold">COLLEGE DROPOUT...</span></b></h1>
                <div className="my-10">
                    {/* <button type="button" className="block text-white bg-gradient-to-br from-purple-500 via-blue-600 via-green-500 via-red-500 to-yellow-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-pink-200 dark:focus:ring-pink-800 font-bold text-xl rounded-full px-16 py-3.5 text-center mb-2 mt-4 uppercase mx-auto">Join the family</button> */}
                    {/* <button type="button" className="block mx-auto text-white bg-lgbt-flag bg-cover focus:ring-4 focus:outline-none focus:ring-pink-200 dark:focus:ring-pink-800 font-bold text-xl rounded-full px-16 py-3.5 text-center mb-2 mt-4 uppercase">Join the family</button> */}
                    <button type="button" className="text-gray-900 bg-lgbt-flag bg-cover hover:bg-lgbt-flag-reverse focus:ring-4 focus:outline-none focus:ring-pink-200 font-bold text-xl rounded-full px-1 py-1 text-center mb-2 mt-4 uppercase block mx-auto">
                        <span className='bg-white block text-gray-900 rounded-full px-16 py-2.5'>
                            Join the family
                        </span>
                    </button>
                </div>
            </div>
        </section>
    );
}

export default FrankCapsuleCollection;